import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/compat/database';

const endpoint = `${environment.apiURL}`;

export interface IPlace {
  company?: any;
  contracts: IContract[];
  hostname: string;
  idCompany?: string;
  idPlace?: string;
  iFoodCredentials?: IIFoodCredentials;
  imageUrl: string;
  name: string;
  path?: string;
  permissionScope: string;
  placeParams?: {
    stockManagement?: boolean;
    defaultPurchargeCredits?: string;
    defaultGlassRange?: [
      { size: number; price: number  }
    ];
    defaultPartnerSource?: string;
    autoReopenTabs?: boolean;
    temperatureRanges?: {
      status: string;
      name: string;
      min: number;
      color: string;
      max: number;
      quantity: number;
    }[];
  };
  port: number;
  protocol: 'wss' | 'ws';
  region: {
    city: string;
    neighborhood: string;
    number: string;
    postalcode: string;
    state: string;
    street: string;
    latitude?: number;
    longitude?: number;
  };
  shippingOptions: any;
  tabModel: 'both-postpaid-prepaid' | 'postpaid' | 'prepaid' | 'glass-prepaid' | 'free';
  theme?: string;
  whatsAppNumber?: string;
}

export interface IIFoodCredentials {
  merchantId?: string;
  catalogId?: string;
  categoryId?: string;
  token: {
      accessToken: string;
      refreshToken: string;
      type: string;
      expiresIn: number;
      expiresAt: number;
  };
  percentageLimit?: number;
  categoryName: string;
  enable?: boolean;
}

export interface IContract {
  active: boolean;
  createdAt: number;
  device: string;
  expiredAt: number;
  suspensionReason?: string;
  plan: 'analytics' | 'self-service';
  shopProductsRestriction?: string[];
  tapsCount: number;
}

@Injectable()
export class PlaceService {

  constructor(private http: HttpClient,
              private db: AngularFireDatabase) { }

  /**
   * Call API that get the place.
   * @param idPlace that represent the place.
   */
  getPlaceById(idPlace: string): Observable<IPlace> {
    return this.http.get<IPlace>(`${endpoint}/places/${idPlace}`);
  }

  /**
   * Return a observable request from Firebase Realtime Database
   * @param idPlace idPlace that represent where is installed Taps
   */
  requestListenerByIdPlace(placeId: string): Observable<any> {
    return this.db.object<any>(`places/${placeId}/tap`).valueChanges();
  }
}
