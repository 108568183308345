import { Routes } from '@angular/router';
import { AuthAnonymouslyResolve } from './shared/services/auth/auth-anonymously.resolve';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'logon',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./views/slideshow/slideshow.module').then(m => m.SlideshowModule),
    resolve: { user: AuthAnonymouslyResolve },
    data: { title: 'Slideshow'}
  },
  {
    path: 'logon',
    loadChildren: () => import('./views/logon/logon.module').then(m => m.LogonModule),
    resolve: { user: AuthAnonymouslyResolve },
    data: { title: 'Logon'}
  },
  {
    path: '**',
    redirectTo: 'logon/404'
  }
];
