export const environment = {
  production: true,
  trackTools: true,
  apiURL: 'https://api-demo.letsenjoy.it',
  wsURL: 'wss://ws-demo.letsenjoy.it',
  gcpApiKey: 'AIzaSyCsEUov5EwLyP2EDVzGOMTz_Z5vHWHtdKE',
  smartLookKey: '809f2c837b058085076bdc25d490463b528e6ea2',
  freshChatToken: 'f622e8fe-1e17-418a-8723-2df4df519ddd',
  freshChatHost: 'https://wchat.freshchat.com',
  recaptchaKey: '6LeGP70ZAAAAAPxf7ZnADk9vBqwp8LGHXTKBZ4Yw',
  vapidPublicKey: 'BPGmLcjcLIg76Wfb6vJU_NleR6UZ-BkD_wXtrEqVbbyc-dACMfmmad2CqSRqB8WlCHDgT75lClSdwko2rusXNeM',
  stage: 'demo',
  userTTL: 3600000,
  DEFAULT_TIMEZONE: 'America/Sao_Paulo',
  firebase: {
    appId: '1:701629955841:web:e19ad4cc9b634178c36500',
    apiKey: 'AIzaSyBvLmggq7anuswvKpx11gOULqATfyG1STQ',
    authDomain: 'enjoy-dev-9c7f9.firebaseapp.com',
    databaseURL: 'https://enjoy-dev-9c7f9.firebaseio.com',
    dynamicLinksPrefix: 'https://enjoyitdev.page.link',
    projectId: 'enjoy-dev-9c7f9',
    storageBucket: 'enjoy-dev-9c7f9.appspot.com',
    messagingSenderId: '701629955841',
    measurementId: 'G-D6BQJRV00Y'
  }
};
