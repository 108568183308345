import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

const endpoint = `${environment.apiURL}`;

export interface ITapListBoardLink {
  original: string;
  short: string;
}

export interface ITapListBoardSlide {
  imageUrl?: string;
  seconds: number;
}

export interface ITapListBoard {
  colors: {
    accent: string;
    background: string;
    cardBackground: string;
    discountBackground: string;
    discountForeground: string;
    foreground: string;
    primary: string;
  };
  ratingScore: boolean;
  autoScroll: boolean;
  facebook: string;
  font: string;
  idTapListBoard: string;
  idPlace: string;
  instagram: string;
  limit: number;
  links?: ITapListBoardLink[];
  name: string;
  slides?: ITapListBoardSlide[];
  styleType: string;
  subtitle: string;
  title: string;
  whatsapp: string;
  fullscreen: boolean;
}

@Injectable()
export class TapListBoardService {

  constructor(private http: HttpClient) {}

  _hasTapLists: Readonly<boolean>;

  getTapListBoards(idPlace: string): Observable<ITapListBoard[]> {
    return this.http.get<ITapListBoard[]>(`${endpoint}/places/${idPlace}/tap-list-boards`).pipe(
      take(1),
      tap((tapListBoards) => {
        this._hasTapLists = tapListBoards.length > 0;
      }),
      catchError((error) => {
        this._hasTapLists = false;
        throw error;
      })
    );
  }

  getTapListBoard(idTapListBoard: string): Observable<ITapListBoard> {
    return this.http.get<ITapListBoard>(`${endpoint}/places/tap-list-boards/${idTapListBoard}`).pipe(
      take(1)
    );
  }

  createTapListBoard(idPlace: string, tapListBoard: ITapListBoard): Observable<ITapListBoard> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post<ITapListBoard>(`${endpoint}/places/${idPlace}/tap-list-boards`, tapListBoard, httpOptions).pipe(
      take(1)
    );
  }

  updateTapListBoard(idPlace: string, idTapListBoard, tapListBoard: ITapListBoard): Observable<ITapListBoard> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.put<ITapListBoard>(`${endpoint}/places/${idPlace}/tap-list-boards/${idTapListBoard}`, tapListBoard, httpOptions).pipe(
      take(1)
    );
  }

  deleteTapListBoard(idTapListBoard: string): Observable<void> {
    return this.http.delete<ITapListBoard>(`${endpoint}/places/tap-list-boards/${idTapListBoard}`).pipe(
      take(1),
      map(() => undefined)
    );
  }
}
