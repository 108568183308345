import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CacheService } from './../cache.service';
import firebase from 'firebase/compat/app';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, from } from 'rxjs';
import { take, switchMap, mapTo } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private _currentUser: Observable<firebase.User | null>;

  constructor(private storage: LocalStorageService,
              private fireAuth: AngularFireAuth,
              private cacheService: CacheService) {
    this._currentUser = fireAuth.authState;
  }

  get currentUser(): Observable<firebase.User | null> {
    return this._currentUser;
  }

  get auth(): AngularFireAuth {
    return this.fireAuth;
  }

  signInWithEmailAndPassword(email: string, password: string): Observable<firebase.auth.UserCredential> {
    return from(this.fireAuth.signInWithEmailAndPassword(email, password));
  }

  signInWithToken(token: string): Observable<firebase.auth.UserCredential> {
    return from(this.fireAuth.signInWithCustomToken(token));
  }

  signInWithGoogle(): Observable<firebase.auth.UserCredential> {
    return from(this.fireAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider()));
  }

  signInAnonymously(): Observable<firebase.auth.UserCredential> {
    return from(this.fireAuth.signInAnonymously());
  }

  signOut() {
    return from(this.fireAuth.currentUser).pipe(
      switchMap((user) => {
        const config = this.storage.retrieve('config');
        this.storage.clear();
        setTimeout(() => this.storage.store('config', config), 1000);
        this.cacheService.clearAll();
        return from(this.fireAuth.signOut()).pipe(mapTo(!!user));
      }),
      take(1)
    );
  }

  sendPasswordResetEmail(email: string): Observable<void> {
    return new Observable((observer) => {
      this.fireAuth.sendPasswordResetEmail(email)
        .catch((error) => {
          const _error = new Error('firebase error');
          let _code: string;
          if (error.code === 'auth/user-not-found') {
            _code = '404';
          } else {
            console.error('unknown firebase error', error);
            _code = 'default';
          }
          (_error as any).error = { errorCode: _code };
          observer.error(_error);
        })
        .then(() => {
          observer.next();
          observer.complete();
        });
    });
  }

  /**
   * Call API that set password of current user
   * @param newPassword that the new password to user
   * @param email that the email of user
   * @param currentPassword that the current password of user
   */
  changePassword(email: string, currentPassword: string, newPassword: string) {
    return new Observable((observer) => {
      this.fireAuth.signInWithEmailAndPassword(email, currentPassword)
      .then(() => this.fireAuth.currentUser)
      .then((user) => user!.updatePassword(newPassword))
      .catch(observer.error)
      .then((r) => {
        observer.next(r);
        observer.complete();
      });
    });
  }
}
