import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { environment } from '../../../environments/environment';

const endpoint = `${environment.apiURL}`;

export interface ILeaderboardUser {
  name: string;
  uid: string;
  mlServed: number;
}

@Injectable()
export class CustomerService {

  constructor(private http: HttpClient,
              private db: AngularFireDatabase) {}

  getConsumerLeaderboard(idPlace: string, idLeaderboard: string) {
    return this.db.list<ILeaderboardUser>(`places/${idPlace}/leaderboard/${idLeaderboard}`,
      (o) => o.orderByChild('mlServed').limitToLast(7)).valueChanges();
  }
}
